/* Font use */

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@700&display=swap');


/* Main Css */

body{
    
font-family: 'Almarai', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
}
a{
    text-decoration: none;
}
.theme_bg{
    background: #6b2463;
    color: #fff;
}
.theme_color {
    background: #fff;
    color: #6b2463;
}
h1,h2,h3,h4,h5,h6,p,li,a{
    font-family: 'Almarai', sans-serif;
}

.breadcrumb{

    background-image:url('./images/heads.png');
    background-position: top;
    background-size: cover;         
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
    height: 250px;
 
}

.breadcrumb h3{

    padding: 60px 100px 100px 100px;
    color: #fff;
    text-align: center;

}

.container
{
    font-family: 'Bai Jamjuree', sans-serif;
}
.container h3 {
    font-family: 'Bai Jamjuree', sans-serif;
        text-transform: capitalize;
    font-weight: 700;

}
.container h5 {
    font-family: 'Bai Jamjuree', sans-serif;

    text-transform: capitalize;
    font-weight: 700;

}
.container h4 {
    font-family: 'Bai Jamjuree', sans-serif;
            text-transform: capitalize;
    font-weight: 700;

}
.container Button{
    text-transform: capitalize;
    background: #6b2463;    
    color: #fff;
    border-radius: 20px;
}
.container Button:hover {
    background-color: #333333;
}
h3{
font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 700;
    font-size: 48px;
}

p{
        font-size: 18px;
         font-weight: 200;
        line-height:28px;
}
li{
    text-transform: capitalize;
}
button{
    text-transform: capitalize;
}
.hero h3{
    font-weight: 700;
        font-family: 'Almarai', sans-serif;
}

.gridshome{
           font-family: 'Almarai', sans-serif;
    margin-top: 200px;}
.gridshome h3{
         font-weight: 700;
}
.gridshome h4 {
    font-weight: 700;
 }
.gridshome h5 {
    font-weight: 700;
    font-size: 14px;
 }

.footer{
    background:#333333;
    background-position:center;
    background-size: cover;
    background-repeat:no-repeat;
    width: 100%;
    height: 80%;
            color:#fff;
            font-size:14px;
  }
.footer h5{
        font-family: 'Bai Jamjuree', sans-serif;

}
.footer a{
    font-size: 14px;
    color: #fff;
    text-decoration: none;
}